import React, { useState, useEffect, useRef } from "react";
import { EnumPlanType } from "../../contracts/types";
import { useGlobalState } from "../../state";
import TabInternet from "./tabInternet.comp";
import TabVoice from "./tabVoice.comp";
import TabBundle from "./tabBundle.comp";
import StickyTabInternet from "./tabStickyInternet.comptsx";
import StickyTabVoice from "./tabStickyVoice.comp";
import StickyTabBundle from "./tabStickyBundle.comp";
import useGetParameters from "../../hooks/api/useGetParameters";

interface ITabProps {
  isPageCompare: boolean;
}

// const useForceRerender = () => {
//   const [, setValue] = useState(0); // we don't need the value of this state.
//   return () => setValue((value) => value + 1);
// };

// const useIsElementVisible = (target: any, options = undefined) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const forceUpdate = useForceRerender();

//   useEffect(() => {
//     forceUpdate(); // to ensure that ref.current is attached to the DOM element
//   }, []);

//   useEffect(() => {
//     if (!target) return;
//     const observer = new IntersectionObserver(handleVisibilityChange, options);
//     observer.observe(target);
//     return () => observer.unobserve(target);
//   }, [target, options]);

//   const handleVisibilityChange: any = ([entry]: any) =>
//     setIsVisible(entry.isIntersecting);
//   return isVisible;
// };

const Tab: React.FC<ITabProps> = ({ isPageCompare }) => {
  const [iFilters, setIFilters] = useGlobalState("filters");

  const {} = useGetParameters();

  // const elementRef = useRef(null); // to hold reference to the component we need to track

  // const isElementVisible = useIsElementVisible(elementRef.current);
  // console.log("isElementVisible => ", isElementVisible);
  // useEffect(() => {
  //   if (isElementVisible == true) {
  //     console.log("yessss");
  //   }
  // }, [isElementVisible]);

  const eleRef = useRef<HTMLInputElement>(null);

  const [isInView, setIsInView] = useState(false);
  const checkInView = () => {
    const ele = eleRef.current;
    // console.log("ele => ", ele);
    if (!ele) {
      return;
    }
    const rect = ele.getBoundingClientRect();
    // console.log(`${rect.top} < ${window.innerHeight} && ${rect.bottom} >= 0`);
    let isYes = rect.top < window.innerHeight && rect.bottom > 0 ? true : false;
    setIsInView(isYes);
  };

  React.useEffect(() => {
    checkInView();
  }, []);

  React.useEffect(() => {
    document.addEventListener("scroll", checkInView);
    window.addEventListener("resize", checkInView);
    return () => {
      document.removeEventListener("scroll", checkInView);
      window.removeEventListener("resize", checkInView);
    };
  }, []);

  React.useEffect(() => {
    const ele = eleRef.current;

    if (!ele) {
      return;
    }
    if (isInView) {
      // Element has come into view
      console.log("checkInView isInView= >", isInView);
      ele.classList.add("card__animated");
    }
  }, [isInView]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("business-phone")) {
      setIFilters({
        ...iFilters,
        planTypeCode: EnumPlanType.BUSINESS_VOICE_PHONE,
      });
    } else if (path.includes("bundles")) {
      setIFilters({
        ...iFilters,
        planTypeCode: EnumPlanType.BUSINESS_BUNDLES,
      });
    } else {
      setIFilters({
        ...iFilters,
        planTypeCode: EnumPlanType.BUSINESS_INTERNET,
      });
    }
  }, []);

  const onClickTab = (e: any, planTypeCodeParam: EnumPlanType) => {
    if (planTypeCodeParam === iFilters.planTypeCode) {
      return;
    }

    setIFilters({ ...iFilters, planTypeCode: planTypeCodeParam });
  };

  const handleChange = (e: any) => {
    let SearchType = e.target.value;
    if (SearchType === iFilters.planTypeCode) {
      return;
    }
    setIFilters({ ...iFilters, planTypeCode: SearchType });
  };

  return (
    <div className="container">
      <div
        ref={eleRef}
        className="p-4 shadow bg-body  non-sticky-search-box search-container"
      >
        <div
          className="nav nav-pills nav-justified flex-column flex-md-row mb-3"
          id="searchcontrol-tab"
          role="tablist"
        >
          {!isPageCompare && (
            <>
              <button
                className={`nav-link search-tab ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
                  "active"
                }`}
                id="searchcontrol-bi-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bi"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bi"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_INTERNET)
                }
              >
                <div className="iconbi align-middle"></div>Business Internet
              </button>
              <button
                className={`nav-link search-tab mx-md-3 mx-md-3 my-2 my-md-0 ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE &&
                  "active"
                }`}
                id="searchcontrol-bp-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bp"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bp"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_VOICE_PHONE)
                }
              >
                <div className="iconbp align-middle"></div>VOIP & Phone
              </button>
              <button
                className={`nav-link search-tab ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES &&
                  "active"
                }`}
                id="searchcontrol-bd-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bd"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bd"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_BUNDLES)
                }
              >
                <div className="iconbd align-middle"></div>Bundles
              </button>
            </>
          )}
        </div>
        <div className="tab-content" id="searchcontrol-tabContent">
          <TabInternet />
          <TabVoice />
          <TabBundle />
        </div>
      </div>
      {isInView == false && (
        <div className="p-4 shadow bg-body sticky-header-search-bar search-container">
          <div className="flex-column flex-md-row">
            <div className="col-md-2 search-bar-blocks">
              <select
                id=""
                name="searchType"
                className=""
                onChange={handleChange}
              >
                <option value="BUSINESS_INTERNET">Business Internet</option>
                <option value="BUSINESS_VOICE_PHONE">VOIP & Phone</option>
                <option value="BUSINESS_BUNDLES">Bundles</option>
              </select>
            </div>
            <div className="col-md-10 search-bar-blocks">
              <div className="tab-content" id="searchcontrol-tabContent">
                {iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && (
                  <StickyTabInternet />
                )}
                {iFilters.planTypeCode ===
                  EnumPlanType.BUSINESS_VOICE_PHONE && <StickyTabVoice />}
                {iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && (
                  <StickyTabBundle />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab;
