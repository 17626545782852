import React from "react";
import {
  EnumInternetPlanFeatures,
  IMinMax,
  IFilterRange,
  IFilterCheckbox,
  ISelectOption,
  EnumFilterType,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import FilterRange from "../filter/filterRange.comp";
import FilterListCheckbox from "../filter/filterListCheckbox.comp";
import useAuditSaveFilter from "../../../hooks/api/useAuditSaveFilter";
import FindPerfectSpeed from "../findPerfectSpeed.com";

const InternetPlanFilters: React.FC = () => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const {} = useAuditSaveFilter();

  let priceOptions: IFilterRange[] = [
    { minMax: { min: 0, max: 100 }, label: "Under $100" },
    { minMax: { min: 100, max: 200 }, label: "$100 to $200" },
    { minMax: { min: 200, max: 300 }, label: "$200 to $300" },
    { minMax: { min: 300, max: 400 }, label: "$300 to $400" },
    { minMax: { min: 400, max: 0 }, label: "$400 & Above" },
  ];

  const onChangePriceValue = (minMax: IMinMax) => {
    setIFilters({
      ...iFilters,
      filterPrice: minMax,
    });

    setAuditFilter(
      EnumFilterType.PRICE,
      minMax.min.toString(),
      minMax.max.toString()
    );
  };

  const speedOptions: IFilterRange[] = [
    { minMax: { min: 0, max: 100 }, label: "Under 100 Mbps" },
    { minMax: { min: 100, max: 250 }, label: "100 Mbps to 250 Mbps" },
    { minMax: { min: 250, max: 500 }, label: "250 Mbps to 500 Mbps" },
    { minMax: { min: 500, max: 1000 }, label: "500 Mbps to 1 Gig" },
    { minMax: { min: 1000, max: 0 }, label: "1 Gig & Above" },
  ];

  const onChangeSpeedValue = (minMax: IMinMax) => {
    setIFilters({
      ...iFilters,
      filterSpeed: minMax,
    });

    setAuditFilter(
      EnumFilterType.SPEED,
      minMax.min.toString(),
      minMax.max.toString()
    );
  };

  const termOptions: IFilterCheckbox[] = iPlanFilterParams.groupByTerms
    ?.sort()
    .map((term: number) => ({ value: term, label: `${term} months` }));

  const onChangeTermValues = (values: number[]) => {
    setIFilters({
      ...iFilters,
      filterTerm: values,
    });

    setAuditFilter(EnumFilterType.TERM, values.toString(), "");
  };

  const featureOptions: IFilterCheckbox[] = [
    { value: EnumInternetPlanFeatures.CABLE, label: "Broadband" },
    { value: EnumInternetPlanFeatures.FIBER, label: "Fiber" },
  ];

  const onChangeFeatureValues = (values: number[]) => {
    setIFilters({
      ...iFilters,
      filterPlanFeatures: values,
    });

    setAuditFilter(EnumFilterType.INTERNET_TYPE, values.toString(), "");
  };

  const providersOptions: IFilterCheckbox[] = iPlanFilterParams.groupByProviders
    ?.sort()
    .map((provider: ISelectOption) => ({
      value: provider.value,
      label: `${provider.label}`,
    }))
    .filter((provider: ISelectOption) => {
      return ["Nextiva", "TPX", "Lingo", "Zoom"].indexOf(provider.label) < 0;
    });

  const onChangeProviderValues = (values: number[]) => {
    setIFilters({
      ...iFilters,
      filterProvider: values,
    });

    setAuditFilter(EnumFilterType.PROVIDER, values.toString(), "");
  };

  const onChangeOnlyBestPerAddress = (e: any) => {
    setIFilters({
      ...iFilters,
      filterOnlyBestPerAddress: e.target.checked,
    });

    setAuditFilter(
      EnumFilterType.BESTPERADDRESS,
      e.target.checked.toString(),
      ""
    );
  };

  const setAuditFilter = (
    type: EnumFilterType,
    value1: string,
    value2: string
  ) => {
    if (type === EnumFilterType.PRICE || type === EnumFilterType.SPEED) {
      if (value1 === "0" && value2 === "0") {
        return;
      }
    }

    setIAuditVisitor({
      ...iAuditVisitor,
      searchFilter: {
        ...iAuditVisitor.searchFilter,
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        filterType: type,
        value1: value1,
        value2: value2,
      },
    });
  };

  return (
    <>
      <FindPerfectSpeed />
      <FilterRange
        label="Price"
        options={priceOptions}
        optionValue={iFilters.filterPrice}
        onChangeValue={onChangePriceValue}
      />
      <FilterRange
        label="Speed"
        options={speedOptions}
        optionValue={iFilters.filterSpeed}
        onChangeValue={onChangeSpeedValue}
      />
      {iPlanFilterParams.multipleAddresses.multipleInternetParams.length >
        1 && (
        <div key={"other"} className="form-inline">
          <span>
            <h6>Other</h6>
          </span>
          <input
            type="checkbox"
            checked={
              iFilters.filterOnlyBestPerAddress
                ? iFilters.filterOnlyBestPerAddress
                : false
            }
            onChange={(e) => {
              onChangeOnlyBestPerAddress(e);
            }}
          />
          <label className="pl-1 pt-sm-0 pt-1">
            Show only the best 5 deals per address
          </label>
        </div>
      )}
      <FilterListCheckbox
        label="Term"
        filterValues={iFilters.filterTerm}
        options={termOptions}
        onChangeValue={onChangeTermValues}
      />
      <FilterListCheckbox
        label="Internet Type"
        filterValues={iFilters.filterPlanFeatures}
        options={featureOptions}
        onChangeValue={onChangeFeatureValues}
      />
      <FilterListCheckbox
        label="Provider"
        filterValues={iFilters.filterProvider}
        options={providersOptions}
        onChangeValue={onChangeProviderValues}
      />
    </>
  );
};

export default InternetPlanFilters;
