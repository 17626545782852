import React from "react";
import {
  EnumVoicePlanFeatures,
  IMinMax,
  ISelectOption,
  IFilterRange,
  IFilterCheckbox,
  EnumFilterType,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import FilterRange from "../filter/filterRange.comp";
import FilterListCheckbox from "../filter/filterListCheckbox.comp";
import useAuditSaveFilter from "../../../hooks/api/useAuditSaveFilter";

const VoicePlanFilters: React.FC = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  // const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const {} = useAuditSaveFilter();

  const priceOptions: IFilterRange[] = [
    { minMax: { min: 0, max: 15 }, label: "Under $15/user/mo." },
    { minMax: { min: 15, max: 20 }, label: "$15 to $20/user/mo." },
    { minMax: { min: 20, max: 40 }, label: "$20 to $40/user/mo." },
    { minMax: { min: 40, max: 0 }, label: "$40 & Above" },
  ];
  //   console.log("iPlanFilterParams ", iPlanFilterParams);
  //   console.log("iFilters ", iFilters);

  const onChangePriceValue = (minMax: IMinMax) => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        filterPrice: minMax,
      },
    });

    setAuditFilter(
      EnumFilterType.PRICE,
      minMax.min.toString(),
      minMax.max.toString()
    );
  };

  const termOptions: IFilterCheckbox[] =
    iPlanFilterParams.voiceParams.termsGroupBy
      ?.sort()
      .map((term: number) => ({ value: term, label: `${term} months` }));

  const onChangeTermValues = (values: number[]) => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        filterTerm: values,
      },
    });
    setAuditFilter(EnumFilterType.TERM, values.toString(), "");
  };

  //   const featureOptions: IFilterCheckbox[] = [
  //     // { value: EnumVoicePlanFeatures.METERED, label: 'Calling Plans' },
  //     { value: EnumVoicePlanFeatures.UNLIMITED, label: "Unlimited Calling" },
  //     { value: EnumVoicePlanFeatures.CHATIM, label: "Chat" },
  //     { value: EnumVoicePlanFeatures.MEETVIDEO, label: "Meet" },
  //   ];

  //   const onChangeFeatureValues = (values: number[]) => {
  //     setIPlanFilterParams({
  //       ...iPlanFilterParams,
  //       voiceParams: {
  //         ...iPlanFilterParams.voiceParams,
  //         filterPlanFeatures: values,
  //       },
  //     });

  //     setAuditFilter(EnumFilterType.FEATURES, values.toString(), "");
  //   };

  const providersOptions: IFilterCheckbox[] = iPlanFilterParams.groupByProviders
    ?.sort()
    .map((provider: ISelectOption) => ({
      value: provider.value,
      label: `${provider.label}`,
    }))
    .filter((provider: ISelectOption) => {
      return (
        ["Nextiva", "TPX", "Lingo", "Spectrum Business", "Zoom"].indexOf(
          provider.label
        ) >= 0
      );
    });

  const onChangeProviderValues = (values: number[]) => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        filterProvider: values,
      },
    });

    setAuditFilter(EnumFilterType.PROVIDER, values.toString(), "");
  };

  const setAuditFilter = (
    type: EnumFilterType,
    value1: string,
    value2: string
  ) => {
    if (type === EnumFilterType.PRICE || type === EnumFilterType.SPEED) {
      if (value1 === "0" && value2 === "0") {
        return;
      }
    }

    setIAuditVisitor({
      ...iAuditVisitor,
      searchFilter: {
        ...iAuditVisitor.searchFilter,
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        filterType: type,
        value1: value1,
        value2: value2,
      },
    });
  };

  return (
    <>
      <FilterRange
        label="Price"
        options={priceOptions}
        optionValue={iPlanFilterParams.voiceParams.filterPrice}
        onChangeValue={onChangePriceValue}
      />
      <FilterListCheckbox
        label="Term"
        filterValues={iPlanFilterParams.voiceParams.filterTerm}
        options={termOptions}
        onChangeValue={onChangeTermValues}
      />
      {/* <FilterListCheckbox
        label="Features"
        filterValues={iPlanFilterParams.voiceParams.filterPlanFeatures}
        options={featureOptions}
        onChangeValue={onChangeFeatureValues}
      /> */}
      <FilterListCheckbox
        label="Provider"
        filterValues={iPlanFilterParams.voiceParams.filterProvider}
        options={providersOptions}
        onChangeValue={onChangeProviderValues}
      />
    </>
  );
};

export default VoicePlanFilters;
